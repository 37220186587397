#gantt-chart {
  overflow: hidden;
}

#date-scroller {
  display: flex;
  width: 100%;
  overflow-x: scroll;
}

#date-scroller::-webkit-scrollbar {
  height: 20px;
  overflow: auto;
}

#date-scroller::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
  padding: 20px;
}

#date-scroller::-webkit-scrollbar-thumb {
  background-color: $pink; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 4px solid white;
}

.year-container {
  display: flex;
  flex-direction: column;
  background-color: $pink-100;

  & + .year-container {
    background-image: linear-gradient(90deg, $pink 0px, $pink 1px, transparent 1px);
  }
}

.year-title {
  padding-left: 5px;
}

.year-content {
  display: flex;
  text-align: center;
}

.month-container {
  display: flex;
  flex-direction: column;

  &.odd {
    background-color: $pink-300;
  }
}

.month-content {
  display: flex;
}

.day-container {
  font-size: 9px;
  min-width: 12px;

  &.odd {
    visibility: hidden;
  }

  &.today {
    visibility: visible;
    background-color: $red;
    color: white;
  }
}

#gantt-container {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  background: repeating-linear-gradient(180deg, white 0px, white 59px, $pink-300 59px, $pink-300 60px);
  border-top: 1px solid $pink-300;
}

#gantt-container::-webkit-scrollbar {
  display: none;
}

.gantt-day-container {
  min-width: 12px;
  min-height: 200px;
  position: relative;

  &.today::after {
    content: '';
    position: absolute;
    top: 0;
    left: 5px;
    width: 1px;
    height: 100%;
    background-color: $red;
    z-index: $zindex-tooltip - 1;
  }
}

.gantt-item {
  padding: 2px;
  border-radius: 4px;
  min-height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  clear: right;
}

.first-due-date-tag {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0.25rem;
}

.gantt-item.start-right {
  right: 0px;
}

.gantt-item.upcoming,
.gantt-item.upcoming .warning-days-tag {
  background-color: $pink-400;
}

.gantt-item.alert-tag,
.gantt-item.alert-tag .warning-days-tag {
  background-color: $yellow;
}

.gantt-item.overdue,
.gantt-item.overdue .warning-days-tag {
  background-color: $red;
  color: white;
}

.gantt-item.completed,
.gantt-item.completed .warning-days-tag {
  background-color: $green;
  color: white;
}

.gantt-item.archived,
.gantt-item.archived .warning-days-tag {
  background-color: $gray-300;
}

.gantt-item.pending,
.gantt-item.pending .warning-days-tag {
  background-color: $yellow;
}

.warning-days-tag {
  text-align: center;
  background-color: $orange;
  position: absolute;
  right: 10px;
  min-width: 100px;
  z-index: 1;
  border-radius: $border-radius 0 0 $border-radius;
  padding: 0.25rem 0;
}

.tooltip-custom {
  opacity: 0;
  width: max-content;
  padding: 0.25rem 0.5rem;
  line-height: 1.3;
  background-color: white;
  color: $dark;
  border: 1px solid $pink;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  transition: opacity 0.2s ease-in-out;
  /* Position the tooltip */
  position: absolute;
  left: 30px;
  z-index: $zindex-tooltip;
}

.tooltip-custom dl {
  max-width: 15rem;
}

.tooltip-custom.normal {
  top: 0;
}

.tooltip-custom.bottom {
  bottom: 0;
}

.gantt-item:hover .tooltip-custom {
  opacity: 1;
}
