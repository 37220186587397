//
// progress.scss
//

// Custom height

.progress-xs {
  height: 0.25rem;
}

// Progress height small
.progress-sm {
  height: 0.5rem;
}

// Progress height medium
.progress-md {
  height: 1rem;
}

// Progress height large
.progress-lg {
  height: 1.25rem;
}

// Progress height extra large
.progress-xl {
  height: 1.5rem;
}
