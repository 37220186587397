//
// footer.scss
//

.footer {
  position: absolute;
  left: $leftbar-width + $body-gutter * 2;
  right: $body-gutter;
  bottom: $body-gutter;
  background-color: #fff;
  box-shadow: $box-shadow;
  border: 1px solid $leftbar-border;
  border-radius: $leftbar-border-radius;
  padding: $body-gutter 0;

  .footer-links {
    a {
      text-decoration: none;
      margin-left: 1.5rem;
      &:hover {
        text-decoration: underline;
      }
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .footer {
    left: $body-gutter !important;
    text-align: center;
  }
}

// Used for account authentication pages
.footer-alt {
  left: 0;
  border: none;
  text-align: center;
}
