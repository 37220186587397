//
// menu.scss
//

.logo-container {
  margin-bottom: $body-gutter;
  background: $red;
  box-shadow: 0 2px 3px 0 rgba(171, 7, 7, 0.3); // darker for logo
  border: 1px solid $red; // so we measure same height as topbar
  border-radius: $leftbar-border-radius;
}

// Logo
.logo {
  display: block;
  line-height: $topbar-height;
  width: $leftbar-width;

  img {
    width: 100%;
    max-width: 4rem;
    height: auto;
    padding: 0.35rem;
  }
}

// Wrapper
.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

//Content Page
.content-page {
  margin-left: $leftbar-width + $body-gutter;
  overflow: hidden;
  padding: $topbar-height + $body-gutter * 2 0 65px;
  min-height: 100vh;
}

// Left Side-menu
.leftside-menu {
  position: fixed;
  width: $leftbar-width;
  bottom: $body-gutter;
  top: $body-gutter;
  left: $body-gutter;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.leftside-border {
  background: $bg-leftbar;
  box-shadow: $box-shadow;
  border: 1px solid $leftbar-border;
  border-radius: $leftbar-border-radius;
  height: calc(100vh - #{$topbar-height + $body-gutter * 3});
}

// Side-nav
.side-nav {
  padding-left: 0;
  list-style-type: none;
  ul {
    list-style-type: none;
  }
  .side-nav-link {
    color: $menu-item;
    display: block;
    padding: 0.75rem 1.25rem;
    font-size: 1.125rem;
    position: relative;
    transition: all 0.4s;
    list-style: none;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      color: $menu-item-hover;
      text-decoration: none;
    }

    &.active {
      color: $menu-item-active;
      background-color: $menu-item-active-bg;
    }

    span {
      vertical-align: middle;
    }

    i {
      display: inline-block;
      // line-height: 1.125rem;
      margin: 0 10px 0 0;
      font-size: 1.125rem;
      vertical-align: middle;
      width: 20px;
    }
  }

  .menu-arrow {
    transition: transform 0.15s;
    position: absolute;
    right: 1.5rem;
    display: inline-block;
    font-family: 'Material Design Icons';
    text-rendering: auto;
    line-height: 1.75rem;
    font-size: 1.25rem;
    transform: translate(0, 0);
    &:before {
      content: '\F035F';
    }
  }

  .badge {
    margin-top: 3px;
  }

  .side-nav-item {
    > a[aria-expanded='true'] {
      > span.menu-arrow {
        transform: rotate(90deg);
      }
    }

    &.menuitem-active {
      > a:not(.collapsed) {
        > span.menu-arrow {
          transform: rotate(90deg);
        }
      }
    }
  }

  .side-nav-title {
    padding: 12px 30px;
    letter-spacing: 0.05em;
    pointer-events: none;
    cursor: default;
    font-size: 0.6875rem;
    text-transform: uppercase;
    color: $menu-item;
    font-weight: $font-weight-bold;
  }

  .menuitem-active {
    > a {
      color: $menu-item-active !important;
    }
  }
}

.side-nav-divider,
.side-nav-divider-light {
  margin-top: 0;
  margin-bottom: 0;
  background: $pink-400;
}

.side-nav-divider-light {
  background: $pink-100;
}

*[dir='ltr'] {
  .side-nav {
    .side-nav-item {
      .menu-arrow {
        &:before {
          content: '\F0141' !important;
        }
      }
    }
  }
}

.side-nav-second-level,
.side-nav-third-level,
.side-nav-forth-level {
  padding-left: 0;

  li {
    a {
      padding: 0.5rem 1.5rem 0.5rem 3.5rem;
      color: $menu-item;
      display: block;
      position: relative;
      transition: all 0.4s;
      font-size: 1.125rem;
      text-decoration: none;
      &:focus,
      &:hover {
        color: $menu-item-hover;
      }

      .menu-arrow {
        line-height: 1.3rem;
      }
    }
    &.active {
      > a {
        color: $menu-item-active;
      }
    }
  }
}

.side-nav-third-level {
  li {
    a {
      padding: 8px 30px 8px 80px;
    }
  }
}

.side-nav-forth-level {
  li {
    a {
      padding: 8px 30px 8px 100px;
    }
  }
}

// Enlarge menu
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) {
  // min-height: 1600px;

  .wrapper {
    // Side menu
    .leftside-menu {
      // position: absolute;
      width: $leftbar-condensed-width;
      z-index: 5;

      .simplebar-mask,
      .simplebar-content-wrapper {
        overflow: visible !important;
      }

      .simplebar-scrollbar {
        display: none !important;
      }

      .simplebar-offset {
        bottom: 0 !important;
      }

      .logo {
        width: $leftbar-condensed-width;
        z-index: 1;
      }
    }

    // Content Page
    .content-page {
      margin-left: $leftbar-condensed-width + $body-gutter;
    }

    //Navbar & Footer
    .navbar-custom,
    .footer {
      left: $leftbar-condensed-width + $body-gutter * 2;
    }
  }

  // Sidebar Menu
  .side-nav {
    .side-nav-title,
    .badge,
    .menu-arrow {
      display: none !important;
    }
    .collapse,
    .collapsing {
      display: none;
      height: inherit !important;
      transition: none !important;

      .side-nav-second-level,
      .side-nav-third-level,
      .side-nav-forth-level {
        display: none !important;
      }
    }

    .side-nav-item {
      position: relative;
      white-space: nowrap;

      .side-nav-link {
        padding: 0.75rem 0.45rem;
        // min-height: 54px;
        transition: none;

        // &:hover,
        // &:active,
        // &:focus {
        //   color: $menu-item-hover;
        // }

        i {
          // font-size: 1.125rem;
          margin-right: 20px;
          margin-left: 6px;
        }

        span {
          display: none;
          padding-left: 10px;
        }
      }

      &:hover {
        .side-nav-link {
          position: relative;
          width: $leftbar-width;
          color: $menu-item-hover;
          background: $menu-item-hover-bg;
          box-shadow: 0 0 0 1px $leftbar-border, $box-shadow;

          span {
            display: inline;
          }
        }

        > ul {
          display: block !important;
          left: $leftbar-condensed-width;
          position: absolute;
          width: $leftbar-width - $leftbar-condensed-width;
          height: auto !important;
          padding: 5px 0;
          z-index: 9999;
          background: $bg-leftbar;
          box-shadow: 0 0 0 1px $leftbar-border, $box-shadow;

          a {
            padding: 8px 20px;
            position: relative;

            &:hover {
              color: $menu-item-hover;
            }
          }
        }

        > .collapse,
        > .collapsing {
          display: block !important;
          transition: none !important;

          > ul {
            display: block !important;
            left: $leftbar-condensed-width;
            position: absolute;

            background: $bg-leftbar;
            border: 1px solid $leftbar-border;
            box-shadow: $box-shadow;
            transform: translateX(-1px); // this is to account for 1px border on UL

            a {
              box-shadow: none;
              padding: 8px 20px;
              position: relative;
              width: $leftbar-width - $leftbar-condensed-width;
              z-index: 6;

              &:hover {
                color: $menu-item-hover;
              }
            }

            li {
              &:hover {
                > .collapse {
                  display: block !important;
                  height: auto !important;
                  transition: none !important;

                  > ul {
                    display: block;
                    left: $leftbar-condensed-width;
                    top: 0;
                    position: absolute;
                    width: $leftbar-width - $leftbar-condensed-width;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  body {
    overflow-x: hidden;
  }
  .leftside-menu {
    box-shadow: $box-shadow;
    display: none;
    z-index: 10 !important;
  }
  .sidebar-enable {
    .leftside-menu {
      display: flex;
      top: 60px; // 60px is the height of the mobile navbar
      left: 0;
      bottom: 0;
      z-index: 1002 !important;

      .logo-container {
        display: none;
      }

      .leftside-border {
        border: 0;
        border-radius: 0;
      }
    }
  }

  .content-page {
    margin-left: 0 !important;
    padding-top: 4rem;
  }
}

.button-menu-mobile {
  .lines {
    width: 18px;
    display: block;
    position: relative;
    height: 16px;
    transition: all 0.5s ease;
    margin-top: 26px;
    margin-left: 10px;
  }
  span {
    height: 2px;
    width: 100%;
    background-color: rgba($white, 0.8);
    display: block;
    margin-bottom: 5px;
    transition: transform 0.5s ease;

    &:nth-of-type(2) {
      width: 24px;
    }
  }

  &.disable-btn {
    display: none;
  }
}

body {
  .side-nav {
    .side-nav-link {
      color: $menu-item;
      &:hover,
      &:focus,
      &:active {
        color: $menu-item-hover;
        background: $menu-item-hover-bg;
      }
    }

    .menuitem-active {
      > a {
        color: $menu-item-active !important;
      }
    }

    .side-nav-title {
      color: $menu-item;
    }

    .side-nav-second-level,
    .side-nav-third-level,
    .side-nav-forth-level {
      li {
        a {
          color: $menu-item;
          &:focus,
          &:hover {
            color: $menu-item-hover;
            background: $menu-item-hover-bg;
          }
        }
        &.active {
          > a {
            color: $menu-item-active;
          }
        }
      }
    }
  }
}
