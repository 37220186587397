.message {
  position: relative;
  background-color: $pink-100;
  border: 1px solid $pink-400;
  margin: 0 0 1rem 1rem;
  padding: 1rem;
  border-radius: $border-radius-xl;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -14px;
    width: 21px;
    height: 19px;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='19' viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6329 1C12.6115 9.05013 9.29808 18.1113 1 18.1113C5.49399 18.742 14.3068 18.312 20.0238 13.8485L13.6329 1Z' fill='%23FFF3EE'/%3E%3Cpath d='M13.6329 1C12.6115 9.05013 9.29808 18.1113 1 18.1113C5.49399 18.742 14.3068 18.312 20.0238 13.8485' stroke='%23FCD2BF'/%3E%3C/svg%3E%0A");
  }
}
