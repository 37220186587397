//
// base-custom-variables.scss
//

// Font weight
$font-weight-medium: 500;
$font-weight-semibold: 600;

:root {
  --bs-gutter-x: 1rem;
}
