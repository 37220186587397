// Border colours

@each $color, $value in $colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

.border-pink-100 {
  border-color: $pink-100 !important;
}

.border-pink-300 {
  border-color: $pink-300 !important;
}

.border-pink-400 {
  border-color: $pink-400 !important;
}

// Border separator

.border-separator-sm {
  border-style: solid;
  border-width: 1px 0 0 0;

  @include media-breakpoint-up(sm) {
    border-width: 0 0 0 1px;
  }
}

// Border radius
.border-radius-xl {
  border-radius: $border-radius-xl !important;
}
