// Background lighten

@each $color, $value in $theme-colors {
  .bg-#{$color} {
    background-color: $value;
  }

  .bg-#{$color}-lighten {
    @include bg-variant-light($value);
  }
}

// Background colours

@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: $value;
  }
}

.bg-pink-100 {
  background-color: $pink-100;
}

.bg-pink-300 {
  background-color: $pink-300;
}

.bg-pink-400 {
  background-color: $pink-400;
}
