//
// topbar.scss
//

.navbar-custom {
  padding-left: $grid-gutter-width;
  background-color: $bg-topbar;
  box-shadow: $box-shadow;
  min-height: $topbar-height;
  position: fixed;
  left: $leftbar-width;
  top: 0;
  right: 0;
  z-index: 1001;

  .topbar-menu {
    position: relative;
    z-index: 1;

    > li {
      float: left;
      max-height: $topbar-height;
    }

    .nav-link {
      padding: 0;
      color: $dark;
      font-weight: $font-weight-normal;
      min-width: 32px;
      display: block;
      text-align: center;
      margin: 0 10px;
      position: relative;
    }

    .nav-user {
      line-height: $topbar-height;
      background: $pink-300;
      padding: 0 1rem;
    }
  }
}

.topbar-dropdown {
  .nav-link {
    line-height: $topbar-height;
  }
}

/* Notification */
.notification-list {
  margin-left: 0;

  .noti-title {
    background-color: transparent;
    padding: 15px 20px;
  }

  .noti-icon {
    font-size: 22px;
    vertical-align: middle;
    line-height: $topbar-height;
  }

  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 22px;
    right: 6px;
    border-radius: 50%;
    height: 7px;
    width: 7px;
    background-color: $danger;
  }
}

.profile-dropdown {
  min-width: 170px;

  i,
  span {
    vertical-align: middle;
  }
}

.topbar-company-title {
  line-height: $topbar-height;
  font-size: $font-size-sm;
}

.button-menu-mobile {
  border: none;
  color: $dark;
  height: $topbar-height;
  line-height: $topbar-height;
  width: 60px;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  float: left;
  z-index: 1;
  position: relative;
  margin-left: -$grid-gutter-width;

  &.disable-btn {
    display: none;
  }

  @include media-breakpoint-down(md) {
    margin: 0 !important;
  }
}

@include media-breakpoint-up(md) {
  .navbar-custom {
    top: $body-gutter;
    right: $body-gutter;
    left: $leftbar-width + $body-gutter * 2;

    border: 1px solid $leftbar-border;
    border-radius: $leftbar-border-radius;
  }
}

@media (max-width: 600px) {
  .navbar-custom {
    .topbar-menu {
      position: initial;
    }

    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .navbar-custom {
    left: 0 !important;
    padding: 0;
  }
  .button-menu-mobile {
    &.disable-btn {
      display: inline-block;
    }
  }
}

@media (max-width: 375px) {
  .navbar-custom {
    .topbar-menu {
      .nav-link {
        margin: 0 6px;
      }
    }

    &.topnav-navbar {
      .container-fluid {
        padding-right: 12px;
        padding-left: 12px;
      }
      .navbar-toggle {
        margin: 27px 3px;
      }
      .button-menu-mobile {
        width: auto;
      }
    }
  }
}
