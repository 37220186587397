//
// custom-forms.scss
//

@each $color, $value in $theme-colors {
  .form-checkbox-#{$color},
  .form-radio-#{$color} {
    .form-check-input {
      &:checked {
        background-color: $value;
        border-color: $value;
      }
    }
  }
  .form-checkbox-#{$color} {
    .form-check-input {
      &:checked {
        background-image: $form-check-input-checked-white-bg-image;
      }
    }
  }
  .form-radio-#{$color} {
    .form-check-input {
      &:checked {
        background-image: $form-check-radio-checked-white-bg-image;
      }
    }
  }
}

.form-check-input {
  background-size: $form-check-input-width $form-check-input-width;
}

.form-switch > .form-check-input {
  background-size: unset;
  border-color: $form-switch-color;

  &:active {
    filter: unset;
  }
  &:checked {
    background-color: $form-switch-focus-color;
  }
}

.form-check-label {
  font-weight: $font-weight-normal;
}
