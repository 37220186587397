//
// tables.scss
//

//Table centered (Custom)
.table-centered {
  th,
  td {
    vertical-align: middle !important;
  }
}

// Custom table components (Custom)
.table {
  .table-user {
    img {
      height: 30px;
      width: 30px;
    }
  }

  .table-actions {
    padding-top: 0;
    padding-bottom: 0;
    vertical-align: middle;
  }

  .action-icon {
    color: $dark;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 0.25rem;

    &:hover {
      color: $red;
    }
  }

  .badge {
    font-size: inherit;
  }
}

// Tables fluid
.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

// search input size for datatables
.dataTables_filter {
  .form-control {
    width: 18rem !important;
  }
}
