//
// pagination.scss
//

.page-item {
  &.active {
    .page-link {
      text-decoration: underline;
    }
  }
}
