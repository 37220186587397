//
// buttons.scss
//

.btn {
  .mdi {
    &:before {
      line-height: initial;
    }
  }
}

// Button Shadows
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-shadow($value);
  }
}

// Button Text
.btn-text-primary {
  font-weight: $font-weight-medium;
  color: $primary;

  &:hover {
    color: $red;
  }
}
