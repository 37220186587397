//
// tabs.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: $gray-700;
      font-weight: $font-weight-medium;
    }
  }
}

.nav-pills {
  > a {
    color: $gray-700;
    font-weight: $font-weight-medium;
  }
}

.bg-nav-pills {
  background-color: $nav-pills-bg;
}

//
// nav-bordered
//

.nav-tabs.nav-bordered {
  border-bottom: 3px solid $gray-300;

  .nav-item {
    margin-bottom: -2px;
  }

  li {
    a {
      border: 0;
      padding: 0.625rem 1.25rem;
    }
    a.active {
      border-bottom: 3px solid $pink;
    }
  }
}

//
// nav-icons
//

.nav-icon-link {
  font-size: 24px;
  padding: 0.25rem !important;
  border: 0 !important;

  &:hover,
  &.active {
    color: $dark;
  }
}
