//
// _badges.scss
//

// Lighten badge (soft)

@each $color, $value in $theme-colors {
  .badge-#{$color}-lighten {
    @include badge-variant-light($value);
    &[href] {
      color: $value;
      text-decoration: none;
      background-color: rgba($value, 0.18);
    }
    &[href] {
      &:hover,
      &:focus {
        color: $value;
        text-decoration: none;
        background-color: rgba($value, 0.4);
      }
    }
  }
}

// Outline badge

@each $color, $value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-variant-outline($value);
    &[href] {
      color: $value;
      text-decoration: none;
      background-color: rgba($value, 0.2);
    }
    &[href] {
      &:hover,
      &:focus {
        color: $value;
        text-decoration: none;
        background-color: rgba($value, 0.2);
      }
    }
  }
}
