//
// text.scss
//

// Weight

.fw-medium {
  font-weight: $font-weight-medium !important;
}

.fw-semibold {
  font-weight: $font-weight-semibold !important;
}
