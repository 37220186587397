//
// bootstrap-touchspin.scss
//

.bootstrap-touchspin {
  .btn {
    .input-group-text {
      padding: 0;
      border: none;
      background-color: transparent;
      color: inherit;
    }
  }
}
