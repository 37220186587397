//
// reboot.scss
//

body {
  &.loading {
    visibility: hidden;
  }
}

button {
  outline: none !important;
}

// Forms

label {
  font-weight: $font-weight-medium;
}

// Address
address.address-lg {
  line-height: 24px;
}

b,
strong {
  font-weight: $font-weight-semibold;
}

th {
  font-weight: $font-weight-medium;
}

dt {
  font-weight: $font-weight-medium;
}
